<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="program-add-wrapper">
      <validation-observer ref="updateProgramCategoryValidation">
        <!-- form -->
        <b-form>
          <b-row>
            <b-col
              md="12"
            >
              <label for="description-form">Options for translate each program category</label>
              <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body
                  v-for="index in countPostCategory"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          v-if="arrayPostCategory.length > 1"
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Title -->
                        <b-col md="6">
                          <b-form-group
                            label="Name"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-form-input
                                v-model="arrayPostCategory[index - 1].name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Language code -->
                        <b-col md="6">
                          <b-form-group
                            label="Language"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="program-add-category"
                                v-model="arrayPostCategory[index - 1].languageCode"
                                :options="lanCodeOptions"
                                item-disabled="disable"
                                :clearable="false"
                                :selectable="(option) => {
                                  return !arrayPostCategory.find(x => x.languageCode.value === option.value)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- description -->
                        <b-col md="12">
                          <b-form-group
                            label="Description"
                            class="mb-2"
                          >
                            <b-form-input
                              v-model="arrayPostCategory[index - 1].description"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-form-group
                        v-slot="{ ariaDescribedby }"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Default"
                          rules="required"
                        >
                          <b-form-radio
                            v-model="numberDefault"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="index - 1"
                          >
                            Default language
                          </b-form-radio>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-card-body>
              </transition-group>
              <b-col
                cols="12"
                class="pb-2"
              >
                <b-button
                  v-if="lanCodeOptions.length > arrayPostCategory.length"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-1"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDescription"
                >
                  Add post category form
                </b-button>
              </b-col>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <validation-provider
                  #default="{ errors }"
                  name="Featured image"
                  rules="required"
                >
                  <b-media
                    v-model="imgID"
                    no-body
                    vertical-align="center"
                    class="flex-column flex-md-row"
                  >
                    <b-media-aside>
                      <b-img
                        ref="refPreviewEl"
                        :src="imgSrc"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                    </b-media-aside>
                    <b-media-body>
                      <div>
                        <small class="text-muted">Required image resolution 850x225, image size 5mb.</small>
                        <b-card-text class="my-50">
                        <!-- <b-link id="blog-image-text">
                      {{ imgFile ? imgFile.name : 'banner.jpg' }}
                    </b-link> -->
                        </b-card-text>
                        <div class="d-inline-block">

                          <b-form-file
                            ref="refInputEl"
                            v-model="imgFile"
                            accept=".jpg, .png, .gif"
                            placeholder="Choose file"
                            @change="onFileChange "
                          />
                        </div>
                      </div>

                    </b-media-body>
                  </b-media>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="$route.params.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmUpdate"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'post-category' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      <!--/ form -->
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BFormFile,
  BImg,
  BOverlay,
  BFormRadio,
} from 'bootstrap-vue'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { slugify, dataURLtoFile, compressedImage } from '@/utils/appUtils'
import updateProgramCategory from './updatePostCategory'

export default {
  components: {
    vSelect,
    BCardBody,
    BCard,
    BForm,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BFormFile,
    BImg,
    BOverlay,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['lanCodeOptions']),
  },
  setup() {
    const {
      arrayPostCategory,
      countPostCategory,
      defaultName,
      defaultDescription,
      slug,
      imgID,
      imgFile,
      imgSrc,
      numberDefault,
      loading,
    } = updateProgramCategory()
    return {
      arrayPostCategory,
      countPostCategory,
      defaultName,
      defaultDescription,
      slug,
      imgID,
      imgFile,
      imgSrc,
      numberDefault,
      loading,
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      // this.compressedImage(files[0])
      this.imgSrc = e.target.result
      this.imgFile = files[0]
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imgSrc = await theReader.result
      }
      theReader.readAsDataURL(files[0])
    },
    compressedImage(file) {
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        const reader = new FileReader()
        reader.onload = readerEvent => {
          const image = new Image()
          image.onload = () => {
            const canvas = document.createElement('canvas')
            const maxSize = 544; let
              { width } = image
            let { height } = image
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width
                width = maxSize
              }
            } else if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            const dataUrl = canvas.toDataURL('image/jpeg')
            this.imgFile = dataURLtoFile(dataUrl, file.name)
          }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      }
    },

    addNewItemInItemFormDescription() {
      this.arrayPostCategory.push({
        name: '',
        languageCode: '',
        description: '',
      })
      this.countPostCategory += 1
    },
    removeItemDescription(index) {
      this.arrayPostCategory.splice(index, 1)
      this.countPostCategory -= 1
    },
    confirmUpdate() {
      this.$refs.updateProgramCategoryValidation.validate().then(success => {
        if (success) {
          this.loading = true
          if (this.imgFile) {
            const formData = new FormData()
            formData.append('file', this.imgFile)
            formData.append('name', 'hehe')
            store.dispatch('program/uploadImage', formData).then(response => {
              this.imgID = response.body.id
              this.update()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
              this.loading = false
            })
          } else {
            this.update()
          }
        }
      })
    },
    update() {
      let arrPostCatePayload = []
      let postCateDefault = null
      if (this.arrayPostCategory.length) {
        postCateDefault = { ...this.arrayPostCategory[this.numberDefault] }
        postCateDefault.isDefaultLanguage = true
        postCateDefault.languageCode = postCateDefault.languageCode.value
        arrPostCatePayload = [...this.arrayPostCategory]
        arrPostCatePayload.splice(this.numberDefault, 1)
      }
      const payload = {
        name: postCateDefault.name,
        description: postCateDefault.description,
        iconMediaId: this.imgID,
        translations: arrPostCatePayload.length ? arrPostCatePayload.map(x => ({
          name: x.name,
          description: x.description,
          languageCode: x.languageCode.value ? x.languageCode.value : x.languageCode,
          isDefaultLanguage: false,
        })) : [],
      }
      if (postCateDefault) {
        payload.translations.push(postCateDefault)
      }
      store.dispatch('postCategory/updatePostCategory', { id: this.$route.params.id, object: payload }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Local Tips category successfuly',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.loading = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
    display: none;
}
</style>
